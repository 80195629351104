body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-pdf__Page {
    display: inline-block;
}
.react-pdf__Document {
    display: inline-grid;
    line-height: initial;
}
.react-pdf__Page__textContent {
    left: -1px;
}
.react-pdf__Page__textContent span {
    opacity: 0.3;
}

.react-pdf__Page__textContent span::selection {
    background-color: blue;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    border: 1px solid #ededed;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    /* -webkit-box-shadow: inset 0 0 6px #C4C4C4; 
     */
    background: #c4c4c4;
    border-radius: 4px;
    /* width: 5px; */
}
