.css-2b097c-container {
    border: 1px groove #666666;
    outline: none !important;
}
.css-yk16xz-control {
    height: 30px !important;
    border: none !important;
    min-height: 30px !important;
    .css-g1d714-ValueContainer {
        height: 100%;
    }
}
.css-1okebmr-indicatorSeparator {
    display: none;
}
.css-1hb7zxy-IndicatorsContainer {
    height: 100% !important;
}
.css-1pahdxg-control {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 30px !important;
    min-height: 30px !important;
    border-color: none;
    outline: none !important;
    padding: 0px;
    &:hover {
        border: none !important;
        border-color: none !important;
        outline: none !important;
    }
}
.css-tlfecz-indicatorContainer {
    padding: 0px 5px;
}
.css-1hwfws3 {
    padding: 0px 8px;
}
